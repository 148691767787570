import React, { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  isBasePlanSubscribed,
  isGrowthPlanSubscribed,
  isGrowthPlusPlanSubscribed,
  isLaunchpadPlanSubscribed,
  isScalePlanSubscribed,
  isStarterPlanSubscribed,
} from '../utils/billing';

const initialValues = {
  stripeSubscriptionId: null,
  stripeSubscriptionStatus: null,
  stripeEcuPaidFor: null,
  stripeCurrentPlanName: null,
  isSubscriptionSuccessful: false,
  stripeSubscriptionStartDate: null,
  stripeSubscriptionRenewalDate: null,
  stripeSubscriptionCancelledAt: null,
  rollupsPaidFor: null,
  pendingTrialDays: null,
  hasRollups: null,
  hasBillingPlan: false,
  isGrowthPlan: null,
  isGrowthPlusPlan: null,
  isGrowthPlusV2Plan: null,
  isGrowthPlusV2PricingPlan: null,
  isGrowthOrGreaterPlan: null,
  isStarterPlan: null,
  isLaunchpadPlan: false,
  canAccess409A: false,
  hideBillingBanner: false,
  isStripePaymentFailing: false,
  showUpgradeBanner: false,
  setShowUpgradeBanner: null,
  isPaidOrTrialCustomer: true,
  trialEndDate: null,
  paymentWallEnabled: false,
  showStakeholderInviteBanner: false,
  setShowStakeholderInviteBanner: null,
  isGrandfatheredGrowth: false,
  isScalePlan: false,
  stripeGrandfatheredPlanExpiryDate: null,
  isGrandfatheredGrowthPlanExpired: false,
  isGrowthPlusRuvCreditsIncluded: false,
};

export const BillingContext = createContext(initialValues);

export const BillingProvider = (props) => {
  const { subscription, children } = props;
  const [showUpgradeBanner, setShowUpgradeBanner] = useState(false);
  const [
    showStakeholderInviteBanner,
    setShowStakeholderInviteBanner,
  ] = useState(false);
  const values = {
    ...initialValues,
    ...subscription,
  };

  const {
    stripeCurrentPlanName,
    stripeSubscriptionStatus,
    pendingTrialDays,
    trialEndDate,
    rollupsPaidFor,
    paymentWallEnabled,
    isGrandfatheredGrowth,
    isGrowthPlusV2PricingPlan,
    stripeGrandfatheredPlanExpiryDate,
  } = values;

  const hasBillingPlan = !!stripeCurrentPlanName;
  const isSubscriptionSuccessful = stripeSubscriptionStatus === 'active';
  const isLaunchpadPlan =
    isSubscriptionSuccessful &&
    isLaunchpadPlanSubscribed(stripeCurrentPlanName);
  const isBasePlan =
    isSubscriptionSuccessful && isBasePlanSubscribed(stripeCurrentPlanName);
  const isStarterPlan =
    isSubscriptionSuccessful && isStarterPlanSubscribed(stripeCurrentPlanName);
  const isGrowthPlan =
    isSubscriptionSuccessful && isGrowthPlanSubscribed(stripeCurrentPlanName);
  const isGrowthPlusPlan =
    isSubscriptionSuccessful &&
    isGrowthPlusPlanSubscribed(stripeCurrentPlanName);
  const isGrowthPlusV2Plan =
    isSubscriptionSuccessful &&
    isGrowthPlusPlanSubscribed(stripeCurrentPlanName) &&
    isGrowthPlusV2PricingPlan;
  const isScalePlan =
    isSubscriptionSuccessful && isScalePlanSubscribed(stripeCurrentPlanName);
  const isGrowthOrGreaterPlan =
    isGrowthPlan || isGrowthPlusPlan || isScalePlan || isGrandfatheredGrowth;
  const hideBillingBanner =
    hasBillingPlan || (!pendingTrialDays && pendingTrialDays !== 0);

  const hasRollups = !!(
    isGrowthOrGreaterPlan ||
    (rollupsPaidFor && rollupsPaidFor > 0)
  );

  const isStripePaymentFailing =
    !!stripeCurrentPlanName && stripeSubscriptionStatus !== 'active';
  const isEquityInfoPresent = !!pendingTrialDays;

  const hidePaymentWall = () => {
    let hidePaymentWallFlag = true;

    if (paymentWallEnabled) {
      if (isEquityInfoPresent) {
        hidePaymentWallFlag = isSubscriptionSuccessful || pendingTrialDays > 0;
      } else {
        hidePaymentWallFlag = !isStripePaymentFailing;
      }
    }

    return hidePaymentWallFlag;
  };

  const isPaidOrTrialCustomer = hidePaymentWall();
  const isGrandfatheredGrowthPlanExpired =
    stripeGrandfatheredPlanExpiryDate &&
    new Date(stripeGrandfatheredPlanExpiryDate) < new Date();

  const isGrowthPlusRuvCreditsIncluded =
    isScalePlan ||
    isGrowthPlusV2Plan ||
    (isGrowthPlusPlan && !isGrandfatheredGrowthPlanExpired) ||
    (isGrandfatheredGrowth && !isGrandfatheredGrowthPlanExpired);

  const canAccess409A = isGrowthOrGreaterPlan;

  const contextValue = {
    ...values,
    hasBillingPlan,
    isLaunchpadPlan,
    isBasePlan,
    isStarterPlan,
    isGrowthPlan,
    isGrowthPlusPlan,
    isGrowthPlusV2Plan,
    isGrowthPlusV2PricingPlan,
    isGrandfatheredGrowthPlanExpired,
    isGrowthPlusRuvCreditsIncluded,
    isScalePlan,
    isGrowthOrGreaterPlan,
    hideBillingBanner,
    canAccess409A,
    isSubscriptionSuccessful,
    rollupsPaidFor,
    hasRollups,
    isPaidOrTrialCustomer,
    isStripePaymentFailing,
    showUpgradeBanner,
    setShowUpgradeBanner,
    trialEndDate,
    showStakeholderInviteBanner,
    setShowStakeholderInviteBanner,
  };

  return (
    <BillingContext.Provider value={contextValue}>
      {children}
    </BillingContext.Provider>
  );
};

BillingProvider.propTypes = {
  subscription: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export const useBillingContext = () => useContext(BillingContext);
