import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const CompanyContext = createContext({
  name: '',
  legalName: '',
  id: '',
  slug: '',
  currency: '',
  currencySign: '',
  website: '',
  icon: '',
  incorporationCountry: '',
  incorporationState: '',
  entityType: '',
  last4PhoneNumberDigits: '',
  isCompanyInvited: true,
  usCo: false,
  liveMode: false,
  devEnv: false,
  localAi: false,
  draftMode: false,
  angelEnv: false,
  stagingEnv: false,
  showCapTable: false,
  showOldRsus: false,
  showNewRsuUI: false,
  showBanking: false,
  showBankingPartnerPlaceholder: false,
  hidePartnerCallout: false,
  showBankDetail: false,
  manageBankDetail: false,
  bankingEligible: false,
  bankingStatus: null,
  bankingOwner: false,
  bankingEditor: false,
  settingsApiHost: '',
  dashboardsApiHost: '',
  defaultLawyer: '',
  defaultLawyerName: '',
  genericDashboard: '',
  genericProducts: [],
  companyParticipantId: null,
  showFxBanking: false,
  hasFxBanking: false,
  hasBankDetails: false,
  hasCryptoBanking: false,
  showCryptoBanking: false,
  cryptoStatus: '',
  showEsop: false,
  onlyShowGlobal: false,
  showEquityOnboarding: false,
  limitedDraftEquityAccess: false,
  registeredAddress: '',
  showSpaBanner: false,
  showAchPayments: false,
  showAchPaymentsOverride: false,
  showSpaSigning: false,
  showBilling: false,
  showAuthorizedUserError: false,
  hasPuzzle: false,
  showAnalytics: false,
  showWorkflows: false,
  showFundraising: false,
  showIntegrations: false,
  showChargeCards: false,
  showChargeCardListing: false,
  hasRampIntegration: false,
  hasBrexIntegration: false,
  showRampOnly: false,
  showPrimaryCounsel: false,
  show83bFilings: false,
  showLegalService: false,
  incorporationInfoId: null,
  stateIncorporatedAt: null,
  incorporationInfoPaymentId: null,
  incorporationPaymentOutstanding: false,
  showHealthCheck: false,
  showExpenseBeta: false,
  hasLawFirm: false,
  onboardingMethod: null,
  onboardingStatus: null,
  capitalRaised: null,
  onboardingFlow: null,
  showEquityBlocksPromo: false,
  stockIssuanceMethod: null,
  isLawFirmOnboardedCompany: false,
  isCurrentUserLawFirmUser: false,
  hasBoiConfig: false,
  hasBoiExemption: false,
  manageDocumentImport: false,
  manage409A: false,
  showEmailLogs: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateCompanyDetails: (data) => {},

  // People tools
  showEmployeesAndIntegrations: false,
  shouldPromptHrisIntegration: true, // for all other uses except visual offers
  showVisualOffers: false, // determines if they can see the tab.
  showBenchmarking: false, // determines if they can see the tab. shouldPromptHrisIntegration determines if they can actually use the tool
  showOfferCalculator: false, // determines if they can see the tab. shouldPromptHrisIntegration determines if they can actually use the tool
  showInitialRoundModal: false,
  showNewShareIssuanceFlow: false,
  stakeholderDashbordEnabled: false,
  showNewVestingTemplateUI: false,
  hasNewSbcExpenseReporting: false,
  showDraftDocumentSetUI: false,

  showCounselPromo: false,
  showInsurancePromo: false,

  showSafts: false,
  ctaPortalEnabled: false,
  showEquityBlocks: false,
  showTokenWarrants: false,
  showNewModifyGrantFlow: false,
  showNewCaptable: false,
  manageFinancingRounds: false,
  manageTeam: false,
  isLawyer: false,
  showNewStakeholderTableUI: false,
  showComplyAppUI: false,
  showNewValuationsUI: false,
  showBankingPartnerUI: false,
  showAskAngelUI: false,
  showTieOutReport: false,
  askAngelTermsAccepted: false,
  hasEquityRounds: false,
  newSecurityStatusesEnabled: false,
  showSafeTransactionsTab: false,
  vestingNotificationsEnabled: false,
  showVestingMailer: false,
  hasMoneyMovement: false,
  showNavNotifications: false,
  displayPartialSharesInCalculations: false,
  showSafeDocumentImport: false,
  showLiquidityProgramUI: false,
  showRuvCloseUI: false,
  allowRuvCloseUnder40k: false,
  showStakeholderAppUI: false,
  showHealthCheckWarnCallout: false,
  showActualNumUnitsExpired: false,
  securityDraftsEnabled: false,
  draftsBannerDismissed: false,
  showStakeholderLimitedUI: false,
  defaultSbcAccountingConfigId: null,
  lawFirm: { id: null, name: null, contactEmail: null },
});

export const CompanyProvider = ({ company, children }) => {
  const [value, setValue] = useState(company);

  const updateCompanyDetails = (newVal) => {
    setValue({
      ...value,
      ...newVal,
    });
  };

  const contextValue = { ...value, updateCompanyDetails };

  return (
    <CompanyContext.Provider value={contextValue}>
      {children}
    </CompanyContext.Provider>
  );
};

CompanyProvider.propTypes = {
  company: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export const useCompanyContext = () => useContext(CompanyContext);
